import React from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

const reports = [
  {
    id: 1,
    title: "Anket Çalışması 1",
    description: "Yeşil Dönüşü ve Sürdürülebilirlik Anketi",
    date: "2024-11-19",
    filePath: "/belgeler/Yeşil Dönüşü ve Sürdürülebilirlik Anketi hk..docx"
  }
];

const AdminBelgeler = () => {
  const handleDownload = (filePath: string) => {
    const link = document.createElement("a");
    link.href = filePath; // Dosya yolunu doğrudan kullan
    link.download = filePath.split("/").pop() || "file"; // Dosya adını çıkar
    link.click();
  };

  return (
    <div className="grid p-1 lg:p-3 justify-content-center">
      {reports.map(report => (
        <div key={report.id} className="col-12 md:col-6 lg:col-4">
          <Card
            title={report.title}
            className="h-full flex flex-column"
            footer={
              <div className="card-footer mt-auto">
                <Button
                  label="Belgeyi İndir"
                  icon="pi pi-download"
                  className="p-button-outlined w-full"
                  onClick={() => handleDownload(report.filePath)}
                />
              </div>
            }
          >
            {/* Açıklama */}
            <p
              className="mt-3 line-clamp-2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                height: "40px"
              }}
            >
              {report.description}
            </p>
            {/* Tarih */}
            <div className="relative">
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  textAlign: "right"
                }}
              >
                {report.date}
              </p>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default AdminBelgeler;
