import React, { useContext, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import formService from "../../services/forms/form.service";
import { AnketRequestDto, HesaplamaAracAnketRequestDto, RaporlamaRequestDto } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import ReactPlayer from "react-player";
import { CheckboxChangeEvent, Checkbox } from "primereact/checkbox";
import ProtokolFirmalarComponent from "../../components/ProtokolFirmalarComponent";
import VideoComponent from "../../components/VideoComponent";
import EgitimQuestionForm from "../../components/EgitimQuestionForm";

const Egitim = () => {
  const toast = useToast();

  const formik = useFormik<AnketRequestDto>({
    initialValues: {
      result1: ""
    },
    validate: values => {
      const errors: any = {};
      if (!values.result1 || values.result1 === "") {
        errors.result1 = "Bir seçenek şeçiniz.";
      }

      return errors;
    },
    onSubmit: async values => {
      formService.egitimAnket(values).then(res => {
        if (res?.result?.error === null) {
          toast.show("Anket kaydedildi.", "success");
        } else {
          toast.show("Anket kaydedilemedi", "error");
        }
      });
    }
  });

  const options = [
    { label: "Çok Faydalı Oldu", value: "cok_oldu" },
    { label: "Biraz Faydalı Oldu", value: "biraz" },
    { label: "Faydalı Olmadı", value: "olmadı" }
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const value = e.value;
    let newAnalysisAreas: string = "";

    if (e.checked) {
      newAnalysisAreas = value;
    }

    formik.setFieldValue("result1", newAnalysisAreas);
  };

  return (
    <>
      {/* <div className="card">
        <div className="text-center mb-5">
          <div className="text-900 text-3xl font-medium mb-3">Eğitim</div>
        </div>
        <div className="flex justify-content-center">
          <div
            className="flex align-items-center"
            style={{
              width: "100%",
              position: "relative",
              aspectRatio: "16 / 9", // 16:9 oranı
              maxHeight: "80vh", // İsterseniz maksimum yükseklik ekleyebilirsiniz
              overflow: "hidden" // Taşmayı engelle
            }}
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=2vopH40G6Q8"
              controls
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
            />
          </div>
        </div>
      </div> */}

      <VideoComponent videoUrl="https://www.youtube.com/watch?v=2vopH40G6Q8" header="Eğitim" />

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Eğitim Anketi</div>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-column align-items-start">
              <h5>Bu eğitim size ne kadar faydalı oldu?</h5>
              <div className="grid">
                {options.map(option => (
                  <div key={option.value} className="col-12 ml-3">
                    <Checkbox
                      inputId={option.value}
                      value={option.value}
                      onChange={handleCheckboxChange}
                      checked={formik.values.result1.includes(option.value)}
                    />
                    <label htmlFor={option.value} className="ml-2">
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>

              {formik.errors.result1 && <div className="p-error">{formik.errors.result1}</div>}
              <Button label="Gönder" type="submit" className="mt-3" />
            </div>
          </form>
        </div>
      </div>

      <ProtokolFirmalarComponent />
    </>
  );
};

export default Egitim;
