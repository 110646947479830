import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/auth";


const cardData = [
  {
    imgSrc: "/images/landing-page/hesaplama.jpg",
    title: "Hesaplama",
    description: "Yeşil dönüşüm ve sürdürülebilirlik politikalarınızı planlamak için gerekli hesaplamalar.",
    path: "/app/karbonayakizi"
  },
  {
    imgSrc: "/images/landing-page/rapor.jpg",
    title: "Raporlama",
    description: "Sürdürülebilirlik, çevresel performans, iyileştirme ve malzeme-kaynak yönetimi raporları.",
    path: "/app/rapor"
  },
  {
    imgSrc: "/images/landing-page/analiz.jpg",
    title: "Analiz",
    description:
      "Yeşil dönüşüm olgunluk analizi, dijital dönüşüm endeksi ve sektör/bölge/ülke genelindeki istatistikler ve raporlar.",
    path: "/app/grafik"
  },
  {
    imgSrc: "/images/landing-page/egitim.jpg",
    title: "Eğitim",
    description: "Sürdürülebilirlik, yenilikçilik, teknoloji, kurumsal gelişim ve iklim değişikliğine uyum eğitimleri.",
    path: "/app/egitim"
  },
  {
    imgSrc: "/images/landing-page/afad.jpg",
    title: "İş Sürekliliği Planı",
    description:
      "Afet durumunda sanayi kuruluşlarının faaliyet göstermesi için AFAD ve Sanayi Bakanlığı ile geliştirdiğimiz işbirliği çalışmaları.",
    path: "/app/is-surekliligi"
  },
  {
    imgSrc: "/images/landing-page/haber.jpg",
    title: "OSBÜK Bülten",
    description:
      "Türkiye genelindeki organize sanayi bölgeleriyle ilgili güncel haberler, projeler ve sektörel gelişmeler.",
    path: "/app/bulten"
  },

  {
    title: "Stratejik plan raporu",
    description: "Paydaş beklenti ve ihtiyaç analizi (2.10.2024)",
    imgSrc: "/images/landing-page/stratejikplan.jpg",
    path: "/app/stratejik-plan-raporu"
  },

  {
    title: "Stratejik Plan Çalışması",
    description: "Stratejik Plan Çalışması.",
    imgSrc: "/images/landing-page/stratejik-plan.jpg",
    path: "/app/stratejik-plan"
  },
  {
    title: "Forum",
    description: "Forum",
    imgSrc: "/images/landing-page/forum.jpg",
    path: "/app/forum-page"
  }
];

function Homepage() {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="card">
      <div className="text-center">
        <h3>Hoş Geldiniz</h3>
        <span>{auth.getUser().firstName + " " + auth.getUser().lastName}</span>
      </div>

      <div className="surface-0 flex justify-content-center">
        <div id="home" className="w-screen landing-wrapper p-fluid overflow-hidden">
          <div id="features" className="py-2 px-1 lg:px-2 mt-2 mx-0 lg:mx-2">
            <div className="grid justify-content-center">
              <div className="grid">
                {cardData.map((card, index) => (
                  <div className="col-12 md:col-6 lg:col-4" key={index}>
                    <div
                      className="card hover-card cursor-pointer"
                      style={{ height: "8rem", padding: "1rem" }}
                      onClick={() => navigate(card.path)}
                    >
                      <div className="flex align-items-center">
                        <img src={card.imgSrc} className="shadow-2 w-6rem h-6rem mr-3" />
                        <div className="flex flex-column justify-content-center">
                          <h5 className="mb-1 text-900 text-lg">{card.title}</h5>
                          <span className="text-600 text-sm">{card.description}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
