import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import { FormResponse } from "../forms/form.dto";
import { CreateForumDto, CreateMessageDto, ForumTopicEnum, ForumWithMessagesDTO, ForumWithoutMessage } from "./forum.dto";

const services = {
  CreateNewForm(dto: CreateForumDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forum/create", dto, config);
  },
  CreateNewMessage(dto: CreateMessageDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forum/create-m", dto, config);
  },
  GetForumByTopic(topic: ForumTopicEnum, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<ForumWithoutMessage[]>(`forum/${topic}/`, config);
  },
  GetForumById(idForum: number, config?: AxiosRequestConfig<any> | undefined) {
    return http.get<ForumWithMessagesDTO>(`forum/id/${idForum}/`, config);
  }
};

export default services;
