import React, { useContext, useRef, useState } from "react";
import { StyleClass } from "primereact/styleclass";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { LayoutContext } from "../../layout/context/layoutcontext";
import { NodeRef } from "../../types/index";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeEvent } from "primereact/inputnumber";
import { useFormik } from "formik";
import { Card } from "primereact/card";
import aosbLogo from "../../assets/images/aosb_ces_logo.jpg";
import formService from "../../services/forms/form.service";
import { AnketRequestDto, HesaplamaAracAnketRequestDto, RaporlamaRequestDto } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import ReactPlayer from "react-player";
import { CheckboxChangeEvent, Checkbox } from "primereact/checkbox";
// import { Toast } from "primereact/toast";

const CalistayHome = () => {
  //const toast = useRef<Toast>(null);
  const toast = useToast();
  const navigate = useNavigate();

  const features = [
    {
      //title: "Anket 1",
      title: "Yeşil Dönüşüm Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/hesaplama.jpg",
      link: "/calistay/anket-alan-1"
    },
    {
      //title: "Anket 2",
      title: "Dijital Dönüşüm Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/rapor.jpg",
      link: "/calistay/anket-alan-2"
    },
    {
      //title: "Anket 3",
      title: "Eğitim Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/analiz.jpg",
      link: "/calistay/anket-alan-3"
    },
    {
      //title: "Anket 4",
      title: "İş Geliştirme Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/egitim.jpg",
      link: "/calistay/anket-alan-4"
    },
    {
      //title: "Anket 5",
      title: "ARGE Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/afad.jpg",
      link: "/calistay/anket-alan-5"
    },
    {
      //title: "Anket 6",
      title: "Kapasite Geliştirme Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/haber.jpg",
      link: "/calistay/anket-alan-6"
    },
    {
      //title: "Anket 7",
      title: "AFET Yönetimi ve İş Sürekliliği  Stratejik Amaçlar Anketi",
      imgSrc: "/images/landing-page/haber.jpg",
      link: "/calistay/anket-alan-7"
    }
  ];

  return (
    <>
      <div className="card">
        <div id="features" className="py-2 px-2 lg:px-4 mt-2 mx-0 lg:mx-4">
          <div className="grid justify-content-center">
            {features.map((feature, index) => (
              <div key={index} className="col-12 md:col-6 lg:col-4">
                <div
                  className="card hover-card cursor-pointer"
                  style={{ height: "8rem", padding: "1rem" }}
                  onClick={() => navigate(feature.link)}
                >
                  <div className="flex align-items-center">
                    <div className="flex flex-column justify-content-center">
                      <h5 className="mb-1 text-900 text-lg">{feature.title}</h5>
                      {/* <span className="text-600 text-sm">{feature.description}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CalistayHome;
