import React from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

const BaskanMesaj = () => {
  return (
    <div className="surface-ground flex justify-content-center p-4">
      <div className="">
        <Card className="p-4 shadow-2 border-round">
          <h1 className="text-center text-primary font-bold mb-4">
            Yeşil dönüşüm ve sürdürülebilirlik merkezinin değerli takipçileri
          </h1>
          <p className="line-height-3">
            OSB'ler, ülkemizin sanayi altyapısının bel kemiğidir. Türkiye'nin üretim gücünün önemli bir bölümünü
            barındıran OSB'ler, sanayide sürdürülebilirlik ve dijitalleşme süreçlerinde lider rol oynamak zorundadır.
            Yeşil ve dijital dönüşüm, sadece çevresel bir sorumluluk değil, aynı zamanda rekabet gücümüzü korumanın ve
            sanayimizin geleceğe uyum sağlamasının anahtarıdır.
          </p>

          <h2 className="text-primary font-bold mt-4">Yeşil Dönüşüm</h2>
          <p className="line-height-3">
            Dünyamız, çevresel sürdürülebilirlik konusunda kritik bir dönemeçten geçiyor. İklim değişikliği ve doğal
            kaynakların hızla tükenmesi, sanayinin yeşil dönüşümünü kaçınılmaz kılıyor. OSB'lerimiz, çevresel etkilerini
            minimize eden, enerji verimliliğini artıran ve yenilenebilir enerji kaynaklarını daha etkin kullanan bir
            üretim modeline geçmelidir.
          </p>
          <p className="line-height-3">
            Avrupa Birliği tarafından uygulamaya konulan Sınırda Karbon Düzenleme Mekanizması (SKDM) gibi uluslararası
            düzenlemeler, karbon ayak izini azaltmayan üreticiler için ek maliyetler getirecek.
          </p>

          <h2 className="text-primary font-bold mt-4">Dijital Dönüşüm</h2>
          <p className="line-height-3">
            Sanayimizin dijitalleşmesi, küresel rekabet gücünü artıracak ve verimlilik seviyelerini üst noktalara
            taşıyacaktır. OSB'lerimizde dijital teknolojilerin entegrasyonu, üretim süreçlerinde büyük verimlilik
            artışlarına yol açacaktır.
          </p>

          <h2 className="text-primary font-bold mt-4">Stratejik Planın Önemi</h2>
          <p className="line-height-3">
            Bugün burada temellerini attığımız stratejik plan, OSB’lerimizin hem yeşil dönüşüm hem de dijitalleşme
            süreçlerinde öncü rol üstlenmesini sağlayacak. OSB'lerimiz, bu dönüşüm süreçlerinin öncüsü olarak
            Türkiye'nin sanayi devrimine liderlik etmelidir.
          </p>
          <Divider />
          <p className="text-center text-500 font-medium">
            OSB'lerimizin yeşil ve dijital dönüşüme liderlik etmesi, sanayimizin sürdürülebilir ve yenilikçi yapıya
            kavuşmasının temel taşlarından biri olacaktır.
          </p>
        </Card>
      </div>
    </div>
  );
};

export default BaskanMesaj;
