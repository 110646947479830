import React, { useState } from "react";
import { Button } from "primereact/button";
import formService from "../../services/forms/form.service";
import { AnketRequestDto } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import { InputTextarea } from "primereact/inputtextarea";
import ProtokolFirmalarComponent from "../../components/ProtokolFirmalarComponent";
import VideoComponent from "../../components/VideoComponent";
const OsbukBulten = () => {
  const toast = useToast();

  const [answer, setAnswer] = useState<string>("");

  const handleSubmit = async () => {
    try {
      if (answer === "") {
        toast.show("Lütfen formu doldurunuz.", "error");
        return;
      }
      const dto: AnketRequestDto = {
        result1: answer ?? ""
      };

      formService
        .haberAnket(dto)
        .then(res => {
          if (res?.result?.error === null) {
            toast.show("Form başarıyla kaydedildi!", "success");
          } else {
            toast.show("Form gönderiminde hata oluştu.", "error");
          }
        })
        .catch(error => {
          toast.show("Sunucu hatası: " + error.message, "error");
        });
    } catch (error) {
      //toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
  };

  return (
    <>
      {/* <div className="card">
        <div className="flex justify-content-center">
          <div
            className="flex align-items-center"
            style={{
              width: "100%",
              position: "relative",
              aspectRatio: "16 / 9", // 16:9 oranı
              maxHeight: "80vh", // İsterseniz maksimum yükseklik ekleyebilirsiniz
              overflow: "hidden" // Taşmayı engelle
            }}
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=d3kwhP3OMPM"
              controls
              width="100%"
              height="100%"
              style={{ position: "absolute", top: 0, left: 0 }}
            />
          </div>
        </div>
      </div> */}

      <VideoComponent videoUrl="https://www.youtube.com/watch?v=d3kwhP3OMPM" header="OSBÜK Bülten" />

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">OSBÜK Bülten Anketi</div>
          </div>
        </div>

        <h5 className="mb-4 font-medium">Bültenimizde hangi konulara yer vermemizi istersiniz?</h5>
        <div className="card flex justify-content-center w-full">
          <InputTextarea
            value={answer}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setAnswer(e.target.value)}
            rows={5}
            cols={30}
            style={{ width: "100%" }} // Genişliği %100 yapma
          />
        </div>

        <div className="m-2 md:m-3" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button label="Kaydet" onClick={handleSubmit} />
        </div>
      </div>

      <ProtokolFirmalarComponent />
    </>
  );
};

export default OsbukBulten;
