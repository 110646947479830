
const DropdownDatas = {
  GetMuvekkilType() {
    return [
      { label: "Gerçek", value: 1 },
      { label: "Tüzel", value: 2 },
    ];
  },

  GetAddressType() {
    return [
      { label: "Ev", value: "Ev" },
      { label: "İş Yeri", value: "İş Yeri" },
      { label: "Okul", value: "Okul" },
      { label: "Cezaevi", value: "Cezaevi" },
      { label: "Diğer", value: "Diğer" },
    ];
  },

  GetCinsiyet() {
    return [
      { label: "Erkek", value: "Erkek" },
      { label: "Kadın", value: "Kadin" },
    ];
  },

  getBaro() {
    return [
      "*BELİRTMEK İSTEMİYORUM",
      "Adana Gıda İhtisas",
      "Adana Hacı Sabancı",
      "Adana Karataş TDİ (Su Ürünleri)",
      "Ceyhan",
      "Kozan",
      "Adıyaman",
    ].map((x) => {
      return { label: x, value: x };
    });
  },


};
export default DropdownDatas;
