import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useToast } from "../../utils/toast";
import { t } from "i18next";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { CreateUserDto } from "../../services/account/account.dto";
import accountService from "../../services/account/account.service";
import { Checkbox } from "primereact/checkbox";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import DropdownDatas from "../../utils/DropdownValues";
import { useAuth } from "../../utils/auth";
import axios from "axios";

const RegisterSebis = () => {
  const auth = useAuth();
  const toast = useToast();

  const navigate = useNavigate();
  const containerClassName = classNames(
    "surface-ground flex align-items-center justify-content-center h-auto w-full p-0"
  );

  const registerFormik = useFormik<CreateUserDto>({
    initialValues: {
      user_name: "",
      email: "",
      password: "",
      re_password: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      osb: "",
      osb_gorevi: ""
    },
    enableReinitialize: true,
    validate: data => {
      const errors: any = {};

      //   if (!data.user_name || data.user_name === "") {
      //     errors.user_name = "Kullanıcı adı gereklidir.";
      //   }

      if (!data.email || data.email === "") {
        errors.email = "E-posta adresi gereklidir.";
      }

      if (!data.password || data.password === "") {
        errors.password = "Şifre gereklidir.";
      }

      if (!data.first_name || data.first_name === "") {
        errors.first_name = "Ad gereklidir.";
      }

      if (!data.last_name || data.last_name === "") {
        errors.last_name = "Soyad gereklidir.";
      }

      //   if (!data.phone_number || data.phone_number === "") {
      //     errors.phone_number = "Telefon numarası gereklidir.");
      //   }

      if (!data.re_password || data.re_password === "") {
        errors.re_password = "Şifre tekrarı gereklidir.";
      }

      if (data.password !== data.re_password) {
        errors.password = "Şifreler eşleşmiyor.";
        errors.re_password = "Şifreler eşleşmiyor.";
      }

      return errors;
    },
    onSubmit: async values => {
      values.phone_number = values.phone_number
        .replace(/_/g, "")
        .replace(/ /g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/-/g, "");

      const res = await accountService.Register(values);
      if (!res?.result?.error && res?.result?.data) {
        auth.removeUserFromStorage();
        auth.setToken(res.result.data, true);
        toast.show("Kayıt işlemi başarılı.", "success");
        navigate("/app/homepage", { replace: true });
      } else {
        toast.show(res?.result?.error || "Kayıt işlemi sırasında bir hata oluştu.", "error");
      }
    }
  });

  return (
    <>
      {/* <form key="sebis-regsiter-form" className={containerClassName} onSubmit={registerFormik.handleSubmit}>
        <div className="flex flex-column align-items-center justify-content-center">
          <div
            style={{
              borderRadius: "56px",
              padding: "0.3rem",
              background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)"
            }}
          >
            <div className="w-full surface-card py-5 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
              <div>
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">SEBİS Kayıt Formu</div>
                  <span className="text-600 font-medium">Hesabınızı oluşturun ve SEBİS'e hemen giriş yapın.</span>
                </div>


                <div>
                  <label htmlFor="email" className="block text-900 text-xl font-medium mb-2">
                    {t("E-posta")}
                  </label>
                  <InputText
                    id="email"
                    type="email"
                    placeholder={t("E-posta Adresi")}
                    className={classNames("w-full md:w-30rem", {
                      "p-invalid": FormikValueIsValid(registerFormik, "email")
                    })}
                    value={registerFormik.values.email}
                    onChange={e => registerFormik.setFieldValue("email", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "email") && (
                    <div className="p-error mt-3">{registerFormik.errors.email}</div>
                  )}

                  <label htmlFor="first_name" className="block text-900 text-xl font-medium mb-2 mt-3">
                    {t("Ad")}
                  </label>
                  <InputText
                    id="first_name"
                    type="text"
                    placeholder={t("Ad")}
                    className={classNames("w-full md:w-30rem", {
                      "p-invalid": FormikValueIsValid(registerFormik, "first_name")
                    })}
                    value={registerFormik.values.first_name}
                    onChange={e => registerFormik.setFieldValue("first_name", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "first_name") && (
                    <div className="p-error mt-3">{registerFormik.errors.first_name}</div>
                  )}

                  <label htmlFor="last_name" className="block text-900 text-xl font-medium mb-2 mt-3">
                    {t("Soyad")}
                  </label>
                  <InputText
                    id="last_name"
                    type="text"
                    placeholder={t("Soyad")}
                    className={classNames("w-full md:w-30rem", {
                      "p-invalid": FormikValueIsValid(registerFormik, "last_name")
                    })}
                    value={registerFormik.values.last_name}
                    onChange={e => registerFormik.setFieldValue("last_name", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "last_name") && (
                    <div className="p-error mt-3">{registerFormik.errors.last_name}</div>
                  )}

                  <label htmlFor="user_name" className="block text-900 text-xl font-medium mb-2 mt-3">
                    {t("Kullanıcı Adı")}
                  </label>
                  <InputText
                    id="user_name"
                    type="text"
                    placeholder={t("Kullanıcı Adı")}
                    className={classNames("w-full md:w-30rem", {
                      "p-invalid": FormikValueIsValid(registerFormik, "user_name")
                    })}
                    value={registerFormik.values.user_name}
                    onChange={e => registerFormik.setFieldValue("user_name", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "user_name") && (
                    <div className="p-error mt-3">{registerFormik.errors.user_name}</div>
                  )}

                  <label htmlFor="password" className="block text-900 font-medium text-xl mb-2 mt-3">
                    {t("Şifre")}
                  </label>
                  <Password
                    inputId="password"
                    value={registerFormik.values.password}
                    onChange={e => registerFormik.setFieldValue("password", e.target.value)}
                    placeholder={t("Şifre")}
                    toggleMask
                    className={classNames("w-full", {
                      "p-invalid": FormikValueIsValid(registerFormik, "password")
                    })}
                    inputClassName="w-full p-3 md:w-30rem"
                  ></Password>
                  {FormikValueIsValid(registerFormik, "password") && (
                    <div className="p-error mt-3">{registerFormik.errors.password}</div>
                  )}

                  <label htmlFor="re_password" className="block text-900 font-medium text-xl mb-2 mt-3">
                    {t("Şifreyi Tekrarla")}
                  </label>
                  <Password
                    inputId="re_password"
                    value={registerFormik.values.re_password}
                    onChange={e => registerFormik.setFieldValue("re_password", e.target.value)}
                    placeholder={t("Şifreyi Tekrarla")}
                    toggleMask
                    className={classNames("w-full", {
                      "p-invalid": FormikValueIsValid(registerFormik, "re_password")
                    })}
                    inputClassName="w-full p-3 md:w-30rem"
                  ></Password>
                  {FormikValueIsValid(registerFormik, "re_password") && (
                    <div className="p-error mt-3">{registerFormik.errors.re_password}</div>
                  )}

                  <div className="w-full">
                    <Button label={"Kayıt Ol"} className="w-full p-3 text-xl mt-5" type="submit"></Button>

                    <div className="flex justify-content-between mt-2">
                      <Button
                        label="OSBÜK.net ile Giriş"
                        className="w-6 mr-1 text-md justify-content-center"
                        onClick={() => {
                          navigate("/loginosbuk");
                        }}
                      />
                      <Button
                        label="Kayıt Ol"
                        className="w-6 ml-1 text-md justify-content-center"
                        onClick={() => {
                          navigate("/register");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form> */}

      <form
        className="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pt-5"
        onSubmit={registerFormik.handleSubmit}
      >
        <div className="flex flex-column align-items-center justify-content-center">
          <div
            style={{
              borderRadius: "56px",
              padding: "0.3rem",
              background: "linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)",
              maxWidth: "1000px"
            }}
          >
            <div className="w-full surface-card py-5 px-5 sm:px-8" style={{ borderRadius: "53px" }}>
              <div className="text-center mb-5">
                <Link to="/">
                  <img src="/images/logoosbuk.png" alt="Image" height="70" className="mb-3" />
                </Link>
                <div className="text-900 text-3xl font-medium mb-3">{t("SEBİS Kayıt Formu")}</div>
                <span className="text-600 font-medium">{t("Hesabınızı oluşturun ve SEBİS'e hemen giriş yapın.")}</span>
              </div>

              <div className="grid">
                <div className="col-12 md:col-6">
                  <label htmlFor="first_name" className="text-primary">
                    {t("İsim")}
                  </label>
                  <InputText
                    id="first_name"
                    type="text"
                    placeholder={t("İsim")}
                    className={classNames("w-full  mt-2", {
                      "p-invalid": FormikValueIsValid(registerFormik, "first_name")
                    })}
                    value={registerFormik.values.first_name}
                    onChange={e => registerFormik.setFieldValue("first_name", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "first_name") && (
                    <div className="p-error mt-3">{registerFormik.errors.first_name}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="last_name" className="text-primary">
                    Soy isim
                  </label>
                  <InputText
                    id="last_name"
                    type="text"
                    placeholder="Soy isim"
                    className={classNames("w-full  mt-2", {
                      "p-invalid": FormikValueIsValid(registerFormik, "last_name")
                    })}
                    value={registerFormik.values.last_name}
                    onChange={e => registerFormik.setFieldValue("last_name", e.target.value.toLocaleUpperCase("tr"))}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "last_name") && (
                    <div className="p-error mt-3">{registerFormik.errors.last_name}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="email" className="text-primary">
                    {t("E-posta")}
                  </label>
                  <InputText
                    id="email"
                    type="email"
                    placeholder={t("E-posta Adresi")}
                    className={classNames("w-full  mt-2", {
                      "p-invalid": FormikValueIsValid(registerFormik, "email")
                    })}
                    value={registerFormik.values.email}
                    onChange={e => registerFormik.setFieldValue("email", e.target.value)}
                    style={{ padding: "1rem" }}
                    autoComplete="email"
                  />
                  {FormikValueIsValid(registerFormik, "email") && (
                    <div className="p-error mt-3">{registerFormik.errors.email}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="phone_number" className="text-primary">
                    {t("Telefon Numarası")}
                  </label>
                  <InputMask
                    id="phone_number"
                    keyfilter="int"
                    mask="(0999) 999-99-99"
                    placeholder={t("Telefon Numarası")}
                    className={classNames("w-full  mt-2", {
                      "p-invalid": FormikValueIsValid(registerFormik, "phone_number")
                    })}
                    value={registerFormik.values.phone_number}
                    onChange={e => registerFormik.setFieldValue("phone_number", e.target.value)}
                    style={{ padding: "1rem" }}
                  />
                  {FormikValueIsValid(registerFormik, "phone_number") && (
                    <div className="p-error mt-3">{registerFormik.errors.phone_number}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="password" className="text-primary">
                    {t("Parola")}
                  </label>
                  <Password
                    inputId="password"
                    value={registerFormik.values.password}
                    onChange={e => registerFormik.setFieldValue("password", e.target.value)}
                    placeholder={t("Parola")}
                    toggleMask
                    className={classNames("block w-full mt-2 login-password", {
                      "p-invalid": FormikValueIsValid(registerFormik, "password")
                    })}
                    autoComplete="new-password"
                    inputClassName={classNames("block w-full p-3", {
                      "p-invalid": FormikValueIsValid(registerFormik, "password")
                    })}
                  ></Password>
                  {FormikValueIsValid(registerFormik, "password") && (
                    <div className="p-error mt-3">{registerFormik.errors.password}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="re_password" className="text-primary">
                    Parola Tekrar
                  </label>
                  <Password
                    inputId="re_password"
                    value={registerFormik.values.re_password}
                    onChange={e => registerFormik.setFieldValue("re_password", e.target.value)}
                    placeholder="Parola Tekrar"
                    toggleMask
                    className={classNames("block w-full mt-2 login-password", {
                      "p-invalid": FormikValueIsValid(registerFormik, "re_password")
                    })}
                    autoComplete="new-password"
                    inputClassName={classNames("block w-full p-3", {
                      "p-invalid": FormikValueIsValid(registerFormik, "re_password")
                    })}
                  ></Password>
                  {FormikValueIsValid(registerFormik, "re_password") && (
                    <div className="p-error mt-3">{registerFormik.errors.re_password}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label className="text-primary" htmlFor="osb">
                    Bağlı Olduğunuz OSB
                  </label>
                  <Dropdown
                    id="osb"
                    name="osb"
                    inputId="osb"
                    filter
                    value={registerFormik.values.osb}
                    onChange={(e: DropdownChangeEvent) => {
                      registerFormik.setFieldValue("osb", e.value);
                    }}
                    options={DropdownDatas.getBaro()}
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Bağlı Olduğunuz OSB"
                    className={classNames("w-full mt-2", {
                      "p-invalid": FormikValueIsValid(registerFormik, "osb")
                    })}
                  />
                  {FormikValueIsValid(registerFormik, "osb") && (
                    <div className="p-error mt-3">{registerFormik.errors.osb}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <label htmlFor="osb_gorevi" className="text-primary">
                    OSB'deki Göreviniz
                  </label>
                  <InputText
                    id="osb_gorevi"
                    type="string"
                    placeholder="OSB'deki Göreviniz"
                    className={classNames("w-full mt-2", {
                      "p-invalid": FormikValueIsValid(registerFormik, "osb_gorevi")
                    })}
                    value={registerFormik.values.osb_gorevi}
                    onChange={e => registerFormik.setFieldValue("osb_gorevi", e.target.value)}
                  />
                  {FormikValueIsValid(registerFormik, "osb_gorevi") && (
                    <div className="p-error mt-3">{registerFormik.errors.osb_gorevi}</div>
                  )}
                </div>

                <div className="w-full mt-3">
                  <Button label={t("Kayıt Ol")} className="w-full text-xl mt-2" type="submit"></Button>
                </div>
                <div className="w-full mt-3 text-center">
                  <Link
                    to="/loginsebis"
                    className="font-medium no-underline ml-2 text-right cursor-pointer"
                    style={{ color: "var(--primary-color)" }}
                  >
                    Zaten hesabınız var mı? Giriş yapın
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default RegisterSebis;
