export interface CreateForumDto {
  forum_topic: ForumTopicEnum | null;
  forum_header: string;
  forum_content: string;
}

export interface ForumWithoutMessage {
    id: number;
    forum_header: string;
    forum_content: string;
    forum_topic: ForumTopicEnum;
    creation_date: Date;
    view_count: number;
    replies: number;
    creator_name: string;
  }

export interface CreateMessageDto {
    forum_id: number; // Mesajın ait olduğu forumun ID'si
  message_content: string; // Mesaj içeriği
}

export enum ForumTopicEnum {
  GENEL = 1,
  YESIL_DONUSUM,
  DIJITAL_DONUSUM,
  EGITIM,
  IS_GELISTIRME,
  ARGE,
  KAPASITE_GELISTIRME,
  IS_SUREKLILIGI
}

export interface ForumMessageDTO {
  id: number; // Mesaj ID
  forum_id: number; // Forum ID
  creator_name: string; // Mesajı gönderen kullanıcı ID'si
  message_content: string; // Mesaj içeriği
  created_at: string; // Mesajın oluşturulma tarihi
}
export interface ForumWithMessagesDTO {
  id: number; // Forum ID
  forum_header: string; // Forum başlığı
  forum_content: string; // Forum içeriği
  forum_topic: string; // Forum konusu (tür)
  viewCount: number; // Görüntülenme sayısı
  replies: number; // Yanıt sayısı
  creation_date: string; // Forumun oluşturulma tarihi
  creator_name: string; // Forumun sahibi/oluşturucusunun adı (e-posta)
  messages: ForumMessageDTO[]; // Forum'a ait mesajların listesi
}

export const getColorForLetter = (letter: string) => {
    const colors = [
      "#4A90E2", // Blue
      "#50E3C2", // Teal
      "#F5A623", // Orange
      "#D0021B", // Red
      "#7ED321", // Green
      "#8B572A", // Brown
      "#B8E986", // Light Green
      "#F8E71C", // Yellow
      "#9B9B9B", // Grey
      "#417505", // Olive Green
      "#D1C4E9", // Light Purple
      "#FF8C00", // Dark Orange
      "#8E44AD", // Purple
      "#E74C3C", // Light Red
      "#3498DB", // Blue
      "#1ABC9C", // Cyan
      "#F39C12", // Yellow Orange
      "#27AE60" // Green
    ];

    // Harften bir index hesapla ve renk seç
    const index = letter.toUpperCase().charCodeAt(0) % colors.length;
    return colors[index];
  };