import React from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

const VideoComponent = (props: { videoUrl?: string; header: string }) => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="text-center mb-5">
        <div className="text-900 text-3xl font-medium mb-3">{t(props.header)}</div>
      </div>

      <div className="flex justify-content-center">
        <div
          className="flex align-items-center"
          style={{
            width: "100%",
            position: "relative",
            aspectRatio: "16 / 9", // 16:9 oranı
            maxHeight: "80vh", // İsterseniz maksimum yükseklik ekleyebilirsiniz
            overflow: "hidden" // Taşmayı engelle
          }}
        >
          <ReactPlayer
            url={props.videoUrl}
            controls
            width="100%"
            height="100%"
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;
