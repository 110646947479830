export interface LoginDto {
  email: string;
  password: string;
}

export interface AccessTokenDto {
  token: string;
  role: string;
}

export interface RefreshTokenDto {
  token: string;
}

export enum Role {
  Admin = "Admin",
  OsbukUser = "OsbukUser",
  User = "User"
}
