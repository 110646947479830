import { Route, Routes } from "react-router-dom";
import App from "../App";
import Login from "../pages/login/Login";
import LandingPage from "../pages/landing/LandingPage";
import KarbonAyakIzi from "../pages/hesaplama/KarbonAyakIzi";
import Grafik from "../pages/analiz/Grafik";
import Egitim from "../pages/egitim/Egitim";
import Raporlama from "../pages/raporlama/Raporlama";
import IsSurekliligi from "../pages/issurekliligi/IsSurekliligi";
import CalistayAnketSurukleBirak from "../pages/calistay/CalistayAnketSurukleBirak";
import CalistayAnketSirala1 from "../pages/calistay/CalistayAnketSirala1";
import CalistayAnketSirala2 from "../pages/calistay/CalistayAnketSirala2";
import CalistayAnketSirala3 from "../pages/calistay/CalistayAnketSirala3";
import CalistayAnketSirala4 from "../pages/calistay/CalistayAnketSirala4";
import CalistayAnketSirala5 from "../pages/calistay/CalistayAnketSirala5";
import CalistayAnketSirala6 from "../pages/calistay/CalistayAnketSirala6";
import CalistayAnketSirala7 from "../pages/calistay/CalistayAnketSirala7";
import NotFoundPage from "../pages/not-found/NotFound";
import OsbukBulten from "../pages/bulten/OsbukBulten";
import MasaSonuc from "../pages/calistay/MasaSonuc";
import MasaSonucListe from "../pages/calistay/MasaSonucListe";
import ProtectedRoute from "./ProtectedRoute";
import CalistayHome from "../pages/calistay/CalistayHome";
import BaskanMesaj from "../pages/landing/BaskanMesaj";
import CalistayAnketYorum1 from "../pages/calistay/anket3/CalistayAnketYorum1";
import CalistayAnketSirala1Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala1Sonuc";
import CalistayAnketYorum1Sonuc from "../pages/calistay/anket3sonuc/CalistayAnketYorum1Sonuc";
import CalistayAnketSirala2Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala2Sonuc";
import CalistayAnketSirala3Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala3Sonuc";
import CalistayAnketSirala4Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala4Sonuc";
import CalistayAnketSirala5Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala5Sonuc";
import CalistayAnketSirala6Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala6Sonuc";
import CalistayAnketSirala7Sonuc from "../pages/calistay/anket2sonuc/CalistayAnketSirala7Sonuc";
import CalistayAnket41 from "../pages/calistay/anket4/CalistayAnket41";
import CalistayAnket4Sonuc1 from "../pages/calistay/anket4sonuc/CalistayAnket4Sonuc1";
import ForumPage from "../pages/forum/ForumPage";
import ForumEkle from "../pages/forum/ForumEkle";
import ForumAyrinti from "../pages/forum/ForumAyrinti";
import StratejikPlanCalismasi from "../pages/stratejik-plan-çalismasi/StratejikPlanCalismasi";
import AdminBelgeler from "../pages/admin-page/AdminBelgeler";
// import ResetPassword from "../pages/reset-password/ResetPassword";
// import { HasPermission } from "../utils/auth";
// import { Permission } from "../services/auth/permissions";
// import NotFoundPage from "../pages/not-found/NotFound";
// import AccessDeniedPage from "../pages/access-denied/AccessDeniedPage";
// import EmailConfirmPage from "../pages/auth/EmailConfirm";
// import ChangeEmailPage from "../pages/auth/ChangeEmail";
// import NewEmailConfirmPage from "../pages/auth/NewEmailConfirm";
// import ChangePasswordPage from "../pages/auth/ChangePassword";
import ProfilePage from "../pages/auth/Profile";
import LoginSebis from "../pages/login/LoginSebis";
import LoginOsbuk from "../pages/login/LoginOsbuk";
import RegisterSebis from "../pages/register/RegisterSebis";
import { HasPermission, useAuth } from "../utils/auth";
import { Role } from "../services/auth/auth.dto";
import AccessDeniedPage from "../pages/access-denied/AccessDeniedPage";
import Homepage from "../pages/homepage/Homepage";
// import RolePage from "../pages/role/Role";

const AppRouter = () => {
  const auth = useAuth();

  const userRole = auth.getUser().role;
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/loginsebis" element={<LoginSebis />} />
      <Route path="/loginosbuk" element={<LoginOsbuk />} />
      <Route path="/register" element={<RegisterSebis />} />
      <Route path="/app" element={<App />}>
        <Route path="profile" element={<ProfilePage />} />

        <Route path="homepage" element={<Homepage />} />

        <Route path="karbonayakizi" element={<KarbonAyakIzi />} />
        <Route path="rapor" element={<Raporlama />} />
        <Route path="grafik" element={<Grafik />} />
        <Route path="egitim" element={<Egitim />} />
        <Route path="is-surekliligi" element={<IsSurekliligi />} />
        <Route path="bulten" element={<OsbukBulten />} />
        <Route path="baskan-mesaj" element={<BaskanMesaj />} />

        <Route path="forum-page" element={<ForumPage />} />
        {/* <Route path="forum-ekle" element={<ForumEkle />} /> */}
        <Route
          path="forum-ekle"
          element={
            <HasPermission role={Role.OsbukUser}>
              <ForumEkle />
            </HasPermission>
          }
        />
        <Route path="forum-ayrinti/:idForum" element={<ForumAyrinti />} />

        <Route path="stratejik-plan" element={<StratejikPlanCalismasi />} />
      </Route>

      <Route path="/admin" element={<App />}>
        <Route
          path="belgeler"
          element={
            <HasPermission role={Role.Admin}>
              <AdminBelgeler />
            </HasPermission>
          }
        />
      </Route>

      <Route path="/calistay" element={<App />}>
        <Route path="home" element={<CalistayHome />} />

        <Route path="masa-sonuc" element={<ProtectedRoute element={<MasaSonuc />} requiredRole="0" />} />
        <Route path="masa-sonuc-liste" element={<ProtectedRoute element={<MasaSonucListe />} requiredRole="-1" />} />

        <Route path="anket-1" element={<CalistayAnketSurukleBirak />} />

        <Route path="anket-alan-1" element={<ProtectedRoute element={<CalistayAnketSirala1 />} requiredRole="-1" />} />
        <Route path="anket-alan-2" element={<ProtectedRoute element={<CalistayAnketSirala2 />} requiredRole="-1" />} />
        <Route path="anket-alan-3" element={<ProtectedRoute element={<CalistayAnketSirala3 />} requiredRole="-1" />} />
        <Route path="anket-alan-4" element={<ProtectedRoute element={<CalistayAnketSirala4 />} requiredRole="-1" />} />
        <Route path="anket-alan-5" element={<ProtectedRoute element={<CalistayAnketSirala5 />} requiredRole="-1" />} />
        <Route path="anket-alan-6" element={<ProtectedRoute element={<CalistayAnketSirala6 />} requiredRole="-1" />} />
        <Route path="anket-alan-7" element={<ProtectedRoute element={<CalistayAnketSirala7 />} requiredRole="-1" />} />

        <Route
          path="anket-alan-1-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala1Sonuc />} requiredRole="-1" />}
        />
        <Route
          path="anket-alan-2-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala2Sonuc />} requiredRole="-1" />}
        />
        <Route
          path="anket-alan-3-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala3Sonuc />} requiredRole="-1" />}
        />
        <Route
          path="anket-alan-4-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala4Sonuc />} requiredRole="-1" />}
        />
        <Route
          path="anket-alan-5-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala5Sonuc />} requiredRole="-1" />}
        />
        <Route
          path="anket-alan-6-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala6Sonuc />} requiredRole="-1" />}
        />
        <Route
          path="anket-alan-7-sonuc"
          element={<ProtectedRoute element={<CalistayAnketSirala7Sonuc />} requiredRole="-1" />}
        />

        <Route
          path="anket-yorum/:id"
          element={<ProtectedRoute element={<CalistayAnketYorum1 />} requiredRole="-1" />}
        />

        <Route
          path="anket-yorum-sonuc/:id"
          element={<ProtectedRoute element={<CalistayAnketYorum1Sonuc />} requiredRole="-1" />}
        />

        <Route path="anket4/:id" element={<ProtectedRoute element={<CalistayAnket41 />} requiredRole="-1" />} />

        <Route
          path="anket4-sonuc/:id"
          element={<ProtectedRoute element={<CalistayAnket4Sonuc1 />} requiredRole="-1" />}
        />
      </Route>

      <Route path="/access-denied" element={<AccessDeniedPage />} />
      <Route path="/not-found" element={<NotFoundPage />} />
      {/*<Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/email-confirm" element={<EmailConfirmPage />} />
      <Route path="/change-email-confirm" element={<NewEmailConfirmPage />} />
      <Route path="/app" element={<App />}>
        <Route
          path="profile"
          element={
            <HasPermission>
              <ProfilePage />
            </HasPermission>
          }
        />
        <Route
          path="change-email"
          element={
            <HasPermission>
              <ChangeEmailPage />
            </HasPermission>
          }
        />
        <Route
          path="change-password"
          element={
            <HasPermission>
              <ChangePasswordPage />
            </HasPermission>
          }
        />
        <Route
          path="roles"
          element={
            <HasPermission permissions={[Permission.RoleDefault]}>
              <RolePage />
            </HasPermission>
          }
        />
      </Route>*/}
    </Routes>
  );
};

export default AppRouter;
