import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useToast } from "../../../utils/toast";
import calistayServices from "../../../services/calistay/calistay.service";
import { AnketYorumDto, Item, ItemWithYorum, SortedItemAnket1 } from "../../../services/calistay/calistay.dto";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useParams } from "react-router-dom";

const initialItems = [
  { id: 1, name: "Enerji Verimliliği ve Yönetimi", yorum: "" },
  { id: 2, name: "Atık Yönetimi ve Geri Dönüşüm", yorum: "" },
  { id: 3, name: "Karbon Ayak İzinin Azaltılması", yorum: "" },
  { id: 4, name: "Su Yönetimi", yorum: "" },
  { id: 5, name: "Çevresel Risk Yönetimi", yorum: "" },
  { id: 6, name: "Yeşil Teknoloji ve İnovasyon", yorum: "" },
  { id: 7, name: "Çevresel Sertifikasyonlar ve Uyum", yorum: "" },
  { id: 8, name: "Sosyal Sorumluluk ve Bilinçlendirme", yorum: "" },
  { id: 9, name: "Sürdürülebilir Tedarik Zinciri Yönetimi", yorum: "" },
  { id: 10, name: "Binalarda Yeşil Dönüşüm ve Enerji Tasarrufu", yorum: "" }
];
const initialItems2 = [
  { id: 1, name: "Dijital Altyapı ve Bağlantı", yorum: "" },
  { id: 2, name: "Endüstri 4.0 Entegrasyonu", yorum: "" },
  { id: 3, name: "Siber Güvenlik", yorum: "" },
  { id: 4, name: "Veri Yönetimi ve Analitiği", yorum: "" },
  { id: 5, name: "Dijital Yetkinlik Geliştirme ve Eğitim", yorum: "" },
  { id: 6, name: "Yapay Zeka ve Makine Öğrenmesi Kullanımı", yorum: "" },
  { id: 7, name: "İnovasyon ve Teknoloji Geliştirme", yorum: "" },
  { id: 8, name: "Dijital Ürün ve Hizmet Geliştirme", yorum: "" },
  { id: 9, name: "Akıllı Enerji Yönetimi", yorum: "" },
  { id: 10, name: "Dijital Sertifikasyon ve Standartlar", yorum: "" }
];
const initialItems3 = [
  { id: 1, name: "Mesleki Eğitim ve Beceri Geliştirme", yorum: "" },
  { id: 2, name: "Dijital Beceriler Eğitimi", yorum: "" },
  { id: 3, name: "Liderlik ve Yönetim Eğitimi", yorum: "" },
  { id: 4, name: "İş Sağlığı ve Güvenliği Eğitimi", yorum: "" },
  { id: 5, name: "Yeşil Dönüşüm ve Sürdürülebilirlik Eğitimi", yorum: "" },
  { id: 6, name: "İnovasyon ve Ar-Ge Eğitimi", yorum: "" },
  { id: 7, name: "Kalite Yönetimi Eğitimi", yorum: "" },
  { id: 8, name: "İş Etiği ve Sosyal Sorumluluk Eğitimi", yorum: "" },
  { id: 9, name: "Ekip Çalışması ve İletişim Becerileri", yorum: "" },
  { id: 10, name: "Çevre ve Atık Yönetimi Eğitimi", yorum: "" },
  { id: 11, name: "Tedarik Zinciri Yönetimi Eğitimi", yorum: "" },
  { id: 12, name: "İnovatif Üretim Teknikleri ve Teknoloji Eğitimi", yorum: "" },
  { id: 13, name: "Finansal Okuryazarlık ve İşletme Yönetimi Eğitimi", yorum: "" }
];
const initialItems4 = [
  { id: 1, name: "İnovasyon ve Yeni Ürün Geliştirme", yorum: "" },
  { id: 2, name: "Uluslararasılaşma ve Pazar Genişletme", yorum: "" },
  { id: 3, name: "Sürdürülebilirlik ve Yeşil İş Modelleri", yorum: "" },
  { id: 4, name: "Dijital Dönüşüm ve Teknoloji Kullanımı", yorum: "" },
  { id: 5, name: "Tedarik Zinciri Yönetimi ve Lojistik Optimizasyonu", yorum: "" },
  { id: 6, name: "İş Birliği ve Kümelenme", yorum: "" },
  { id: 7, name: "Pazar Araştırması ve Müşteri İlişkileri Yönetimi (CRM)", yorum: "" },
  { id: 8, name: "E-Ticaret ve Dijital Pazarlama Stratejileri", yorum: "" },
  { id: 9, name: "Yatırım ve Finansal Yönetim", yorum: "" },
  { id: 10, name: "İşgücü Gelişimi ve Yetenek Yönetimi", yorum: "" },
  { id: 11, name: "Kalite Yönetim Sistemleri ve Standartlara Uyum", yorum: "" },
  { id: 12, name: "Maliyet Yönetimi ve Operasyonel Verimlilik", yorum: "" },
  { id: 13, name: "Stratejik Planlama ve Risk Yönetimi", yorum: "" },
  { id: 14, name: "Müşteri Deneyimi ve Hizmet Kalitesi", yorum: "" },
  { id: 15, name: "Girişimcilik Destek Programları", yorum: "" }
];
const initialItems5 = [
  { id: 1, name: "İnovasyon Kapasitesinin Geliştirilmesi", yorum: "" },
  { id: 2, name: "AR-GE Yatırımlarının Artırılması", yorum: "" },
  { id: 3, name: "Teknoloji Transferi ve Üniversite-Sanayi İş Birliği", yorum: "" },
  { id: 4, name: "Patent ve Fikri Mülkiyet Hakları Yönetimi", yorum: "" },
  { id: 5, name: "AR-GE Projelerinin Yönetimi ve Takibi", yorum: "" },
  { id: 6, name: "Ulusal ve Uluslararası AR-GE Fonlarına Erişim", yorum: "" },
  { id: 7, name: "Yüksek Teknoloji Kullanımı ve Geliştirilmesi", yorum: "" },
  { id: 8, name: "Sürdürülebilirlik Odaklı AR-GE", yorum: "" },
  { id: 9, name: "AR-GE İnsan Kaynağı ve Uzmanlık Geliştirme", yorum: "" },
  { id: 10, name: "Uluslararası AR-GE İş Birlikleri ve Kümelenme", yorum: "" },
  { id: 11, name: "Ürün Yaşam Döngüsü Yönetimi (PLM)", yorum: "" },
  { id: 12, name: "Ticarileştirme ve Pazar Odaklı AR-GE", yorum: "" },
  { id: 13, name: "Kalite Standartları ve Sertifikasyon", yorum: "" },
  { id: 14, name: "AR-GE Performans Ölçümü ve Raporlama", yorum: "" },
  { id: 15, name: "AR-GE Merkezi ve Altyapı Geliştirme", yorum: "" }
];
const initialItems6 = [
  { id: 1, name: "Üretim Kapasitesinin Artırılması", yorum: "" },
  { id: 2, name: "Altyapı ve Teknolojik Donanım İyileştirmesi", yorum: "" },
  { id: 3, name: "İşgücü Geliştirme ve Eğitim", yorum: "" },
  { id: 4, name: "Enerji Verimliliği ve Yönetimi", yorum: "" },
  { id: 5, name: "Ürün Çeşitlendirme ve Katma Değerli Üretim", yorum: "" },
  { id: 6, name: "Ar-Ge ve İnovasyon Kapasitesinin Geliştirilmesi", yorum: "" },
  { id: 7, name: "Tedarik Zinciri ve Lojistik Yönetiminin Geliştirilmesi", yorum: "" },
  { id: 8, name: "Uluslararası Pazar Erişimi ve İhracat Kapasitesi", yorum: "" },
  { id: 9, name: "Sürdürülebilir Üretim ve Çevre Yönetimi", yorum: "" },
  { id: 10, name: "Finansal Yönetim ve Yatırım Kapasitesinin Artırılması", yorum: "" },
  { id: 11, name: "Kalite Yönetimi ve Standartlara Uyum", yorum: "" },
  { id: 12, name: "Dijital Dönüşüm Kapasitesinin Artırılması", yorum: "" },
  { id: 13, name: "İş Sağlığı ve Güvenliği Kapasitesinin Geliştirilmesi", yorum: "" },
  { id: 14, name: "Kriz Yönetimi ve Esneklik Kapasitesi", yorum: "" },
  { id: 15, name: "Stratejik Planlama ve Uzun Vadeli Büyüme", yorum: "" }
];

const initialItems7 = [
  { id: 1, name: "Afet Risk Değerlendirmesi ve Yönetimi", yorum: "" },
  { id: 2, name: "Acil Durum Müdahale Planları", yorum: "" },
  { id: 3, name: "İş Sürekliliği Planlaması", yorum: "" },
  { id: 4, name: "Kriz Yönetimi ve İletişim", yorum: "" },
  { id: 5, name: "Afet Eğitim ve Tatbikatları", yorum: "" },
  { id: 6, name: "Altyapı Güvenliği ve Dayanıklılığı", yorum: "" },
  { id: 7, name: "Siber Güvenlik ve Veri Koruma", yorum: "" },
  { id: 8, name: "Tedarik Zinciri Risk Yönetimi", yorum: "" },
  { id: 9, name: "Afet Sonrası Yeniden Yapılanma ve İyileşme Planları", yorum: "" },
  { id: 10, name: "İş Sağlığı ve Güvenliği Entegrasyonu", yorum: "" },
  { id: 11, name: "Afet Sonrası Psikososyal Destek", yorum: "" },
  { id: 12, name: "Afet ve Kriz Yönetimi İçin Teknoloji Kullanımı", yorum: "" },
  { id: 13, name: "Afet Yönetimi İçin Koordinasyon ve İş Birliği", yorum: "" },
  { id: 14, name: "Afet Erken Uyarı Sistemleri", yorum: "" },
  { id: 15, name: "Sigorta ve Finansal Koruma Stratejileri", yorum: "" }
];

const CalistayAnketYorum1 = (props: { idAnket?: string }) => {
  const toast = useToast();
  let { id } = useParams();

  useEffect(() => {
    if (props.idAnket) {
      if (props.idAnket === "1") {
        setItems(initialItems);
        setHeader("Yeşil Dönüşüm Stratejik Amaçlar");
      } else if (props.idAnket === "2") {
        setItems(initialItems2);
        setHeader("Dijital Dönüşüm Stratejik Amaçlar");
      } else if (props.idAnket === "3") {
        setItems(initialItems3);
        setHeader("Eğitim Stratejik Amaçlar");
      } else if (props.idAnket === "4") {
        setItems(initialItems4);
        setHeader("İş Geliştirme Stratejik Amaçlar");
      } else if (props.idAnket === "5") {
        setItems(initialItems5);
        setHeader("AR-GE Stratejik Amaçlar");
      } else if (props.idAnket === "6") {
        setItems(initialItems6);
        setHeader("Kapasite Geliştirme Stratejik Amaçlar");
      } else if (props.idAnket === "7") {
        setItems(initialItems7);
        setHeader("AFET Yönetimi ve İş sürekliliği Stratejik Amaçlar");
      }
    }
    if (id) {
      if (id === "1") {
        setItems(initialItems);
        setHeader("Yeşil Dönüşüm Stratejik Amaçlar");
      } else if (id === "2") {
        setItems(initialItems2);
        setHeader("Dijital Dönüşüm Stratejik Amaçlar");
      } else if (id === "3") {
        setItems(initialItems3);
        setHeader("Eğitim Stratejik Amaçlar");
      } else if (id === "4") {
        setItems(initialItems4);
        setHeader("İş Geliştirme Stratejik Amaçlar");
      } else if (id === "5") {
        setItems(initialItems5);
        setHeader("AR-GE Stratejik Amaçlar");
      } else if (id === "6") {
        setItems(initialItems6);
        setHeader("Kapasite Geliştirme Stratejik Amaçlar");
      } else if (id === "7") {
        setItems(initialItems7);
        setHeader("AFET Yönetimi ve İş sürekliliği Stratejik Amaçlar");
      }
    }
  }, [id]);

  const [items, setItems] = useState<ItemWithYorum[]>(initialItems);
  const [header, setHeader] = useState<string>("");

  const onYorumChange = (e: React.ChangeEvent<HTMLTextAreaElement>, rowIndex: number) => {
    const newItems = [...items];
    newItems[rowIndex].yorum = e.target.value; // Yorum değerini güncelle
    setItems(newItems); // Yeni durumu ayarla
  };

  // Yorum alanı ve gönder butonunu içeren bileşen
  const yorumBodyTemplate = (rowData: ItemWithYorum) => {
    const rowIndex: number = rowData.id - 1;
    return (
      <div>
        <textarea
          value={rowData.yorum}
          onChange={e => onYorumChange(e, rowIndex)}
          style={{ width: "100%", height: "50px" }}
        />
      </div>
    );
  };

  const handleSubmit = async () => {
    try {
      // Filter items to get only those with a non-empty yorum
      const filledItems = items.filter(item => item.yorum.trim() !== "");
      if (filledItems.length < 3) {
        toast.show("En az 3 tane yorum yapmalısınız.", "error");
        return;
      }

      if (id) {
        // Create DTO based on filled items
        const dto: AnketYorumDto = {
          result1: filledItems[0]?.name || "",
          yorum1: filledItems[0]?.yorum || "",
          result2: filledItems[1]?.name || "",
          yorum2: filledItems[1]?.yorum || "",
          result3: filledItems[2]?.name || "",
          yorum3: filledItems[2]?.yorum || "",
          result4: filledItems[3]?.name || "",
          yorum4: filledItems[3]?.yorum || "",
          result5: filledItems[4]?.name || "",
          yorum5: filledItems[4]?.yorum || "",
          result6: filledItems[5]?.name || "",
          yorum6: filledItems[5]?.yorum || "",
          result7: filledItems[6]?.name || "",
          yorum7: filledItems[6]?.yorum || "",
          result8: filledItems[7]?.name || "",
          yorum8: filledItems[7]?.yorum || "",
          result9: filledItems[8]?.name || "",
          yorum9: filledItems[8]?.yorum || "",
          result10: filledItems[9]?.name || "",
          yorum10: filledItems[9]?.yorum || "",
          result11: filledItems[10]?.name || "",
          yorum11: filledItems[10]?.yorum || "",
          result12: filledItems[11]?.name || "",
          yorum12: filledItems[11]?.yorum || "",
          result13: filledItems[12]?.name || "",
          yorum13: filledItems[12]?.yorum || "",
          result14: filledItems[13]?.name || "",
          yorum14: filledItems[13]?.yorum || "",
          result15: filledItems[14]?.name || "",
          yorum15: filledItems[14]?.yorum || "",
          anket: id
        };

        calistayServices
          .AnketYorum1(dto)
          .then(res => {
            if (res?.result?.error === null) {
              toast.show("Form başarıyla kaydedildi!", "success");
            } else {
              toast.show("Form gönderiminde hata oluştu.", "error");
            }
          })
          .catch(error => {
            toast.show("Sunucu hatası: " + error.message, "error");
          });
      }
    } catch (error) {
      console.error(error);
      toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
  };

  return (
    <div className="card px-2 sm:px-4">
      <h5 className="text-center mb-4 font-medium">{header}</h5>

      <div className="w-full surface-card">
        <DataTable
          value={items}
          tableStyle={{ minWidth: props.idAnket ? "15rem" : "60rem" }}
          scrollable
          columnResizeMode="expand"
        >
          {!props.idAnket && <Column field="id" header="Id"></Column>}
          <Column field="name" header="Amaç Adı" style={{ width: props.idAnket ? "5rem" : "auto" }} />
          <Column body={yorumBodyTemplate} header="Yorum" style={{ width: props.idAnket ? "10rem" : "auto" }} />
        </DataTable>
      </div>
      {!props.idAnket && (
        <div className="m-2 md:m-3" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button label="Gönder" onClick={handleSubmit} className="mr-2" />
        </div>
      )}
    </div>
  );
};

export default CalistayAnketYorum1;
