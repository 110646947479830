import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useToast } from "../../utils/toast";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import calistayServices from "../../services/calistay/calistay.service";
import { Item, SortedItemAnket1 } from "../../services/calistay/calistay.dto";

const initialItems = [
  { id: "1", name: "Mesleki Eğitim ve Beceri Geliştirme" },
  { id: "2", name: "Dijital Beceriler Eğitimi" },
  { id: "3", name: "Liderlik ve Yönetim Eğitimi" },
  { id: "4", name: "İş Sağlığı ve Güvenliği Eğitimi" },
  { id: "5", name: "Yeşil Dönüşüm ve Sürdürülebilirlik Eğitimi" },
  { id: "6", name: "İnovasyon ve Ar-Ge Eğitimi" },
  { id: "7", name: "Kalite Yönetimi Eğitimi" },
  { id: "8", name: "İş Etiği ve Sosyal Sorumluluk Eğitimi" },
  { id: "9", name: "Ekip Çalışması ve İletişim Becerileri" },
  { id: "10", name: "Çevre ve Atık Yönetimi Eğitimi" },
  { id: "11", name: "Tedarik Zinciri Yönetimi Eğitimi" },
  { id: "12", name: "İnovatif Üretim Teknikleri ve Teknoloji Eğitimi" },
  { id: "13", name: "Finansal Okuryazarlık ve İşletme Yönetimi Eğitimi" }
];

const CalistayAnketSirala3 = () => {
  const toast = useToast();

  const [items, setItems] = useState<Item[]>(initialItems);

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);
    setItems(reorderedItems);
  };

  const handleSubmit = async () => {
    try {
      let masaNo = undefined;


      if (masaNo) {
        const dto: SortedItemAnket1 = {
          masaNo: masaNo,
          result1: items[0].name,
          result2: items[1].name,
          result3: items[2].name,
          result4: items[3].name,
          result5: items[4].name,
          result6: items[5].name,
          result7: items[6].name,
          result8: items[7].name,
          result9: items[8].name,
          result10: items[9].name,
          result11: items[10].name,
          result12: items[11].name,
          result13: items[12].name,
          result14: items[13] ? items[13].name : ""
        };

        calistayServices
          .AnketSirala3(dto)
          .then(res => {
            if (res?.result?.error === null) {
              toast.show("Form başarıyla kaydedildi!", "success");
            } else {
              toast.show("Form gönderiminde hata oluştu.", "error");
            }
          })
          .catch(error => {
            toast.show("Sunucu hatası: " + error.message, "error");
          });
      }
    } catch (error) {
      console.error(error);
      toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
  };

  const addNewItem = () => {
    if (!newItemName) {
      toast.show("Lütfen bir değer girin.", "error");
      return;
    }

    const newItem = { id: String(items.length + 1), name: newItemName }; // Generate a new ID
    setItems([...items, newItem]); // Add the new item to the list
    setNewItemName(""); // Clear the input field
    setItemAdded(true); // Mark that an item has been added
    toast.show("Yeni madde başarıyla eklendi!", "success");
  };
  const [newItemName, setNewItemName] = useState<string>(""); // State for the new item name
  const [itemAdded, setItemAdded] = useState<boolean>(false);

  return (
    <div className="card ">
      <h5 className="text-center mb-4 font-medium">Eğitim Stratejik Amaçlar</h5>

      <div className="flex justify-center p-0 md:p-4">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-source">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full bg-gray-100 p-2 md:p-4 rounded"
              >
                <h5 className="mb-4 font-medium">Sıralama Alanı</h5>
                {items.map(({ id, name }, index) => (
                  <Draggable key={id} draggableId={id} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="p-2 mb-2 bg-white rounded border border-gray-200 flex items-center"
                      >
                        <span className="mr-2 md:mr-4">{index + 1}.</span> {/* Sıra numarası */}
                        {name}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="m-1 md:m-3 flex justify-end">
        <input
          type="text"
          value={newItemName}
          onChange={e => setNewItemName(e.target.value)}
          placeholder="Yeni madde adı"
            className="p-inputtext p-component mr-1 w-10 md:w-40"
        />
        <Button label="Ekle" onClick={addNewItem} disabled={itemAdded} /> {/* Disable button if item added */}
      </div>

      {/* <div className="m-2 md:m-3 mt-4" style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button label="Kaydet" onClick={handleSubmit} />
      </div> */}
    </div>
  );
};

export default CalistayAnketSirala3;
