import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import formService from "../../services/forms/form.service";
import { GrafikAnketRequestDto } from "../../services/forms/form.dto";
import { useToast } from "../../utils/toast";
import { Chart } from "primereact/chart";
import { CheckboxChangeEvent, Checkbox } from "primereact/checkbox";
import ProtokolFirmalarComponent from "../../components/ProtokolFirmalarComponent";
// import { Toast } from "primereact/toast";

const Grafik = () => {
  //const toast = useRef<Toast>(null);
  const toast = useToast();

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: [
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023"
      ], // Yıllar
      datasets: [
        {
          label: "Arındırılmamış Endeks",
          data: [
            51.44,
            59.35,
            61.93,
            65.98,
            69.89,
            74.15,
            76.67,
            83.63,
            84.55,
            84.03,
            85.88,
            100.01,
            105.01,
            106.7,
            102.89
          ],
          fill: false,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0.4
        },
        {
          label: "Mevsim ve takvim etkilerinden arındırılmış endeks",
          data: [
            51.2,
            59.02,
            61.42,
            65.67,
            69.52,
            73.52,
            76.09,
            82.75,
            84.09,
            83.47,
            84.92,
            100.0,
            104.4,
            106.34,
            106.58
          ],
          fill: false,
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          tension: 0.4
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  const [chartData2, setChartData2] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz"],
      datasets: [
        {
          label: "Takvim etkisinden arındırılmış yıllık değişim(%)",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [1.3, 11.1, 4.6, -0.7, 0.1, -5.0, -4.0, -5.3]
        },
        {
          label: "Mevsim ve takvim etkisinden arındırılmış aylık değişim(%)",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: [0.1, 2.5, -0.2, -5.0, 1.8, -2.5, 0.3, -1.6]
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };

    setChartData2(data);
    setChartOptions2(options);
  }, []);

  const [anketItems, setAnketItems] = useState<string[]>([]);

  const handleSubmit = async () => {
    try {
      const dto: GrafikAnketRequestDto = {
        result1: anketItems[0] ?? "",
        result2: anketItems[1] ?? "",
        result3: anketItems[2] ?? ""
      };

      formService
        .GrafikAnket(dto)
        .then(res => {
          if (res?.result?.error === null) {
            toast.show("Form başarıyla kaydedildi!", "success");
          } else {
            //toast.show("Form gönderiminde hata oluştu.", "error");
          }
        })
        .catch(error => {
          //toast.show("Sunucu hatası: " + error.message, "error");
        });
    } catch (error) {
      //toast.show("Bir hata oluştu. Lütfen tekrar deneyin.", "error");
    }
  };

  const options = [
    { label: "Gıda ürünleri imalatı", value: "gida" },
    { label: "Tekstil ürünleri imalatı", value: "tekstil" },
    { label: "Deri ve ilgili ürünlerin imalatı", value: "deri" },
    { label: "Ağaç, ağaçve mantar ürünleri imalatı (mobilya hariç)", value: "agac" },
    { label: "Kimyasalların ve kimyasal ürünlerin imalatı)", value: "kimyasal" },
    { label: "Ana metal sanayii", value: "ana_metal" },
    { label: "Bilgisayarların, elektronik ve optik ürünlerin imalatı", value: "bilgisayar" },
    { label: "Elektrikli teçhizat imalatı", value: "elektrikli_tehcizat" },
    {
      label: "Motorlu kara taşıtı, treyler (römork) ve yarı treyler (yarı römork) tarım makineleri",
      value: "motorlu_tasit"
    },
    { label: "Elektrik, gaz, buhar ve iklimlendirme üretimi ve dağıtımı", value: "elektirk_gaz_buhar" },
    { label: "Enerji", value: "enerji" },
    { label: "Kagıt", value: "kagit" }
  ];

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e; // Değeri ve durumunu al

    if (checked) {
      if (anketItems.length < 3) {
        // Yeni seçeneği ekle

        setAnketItems(prev => [...prev, value]);
      } else {
        toast.show("En fazla 3 seçenek seçebilirsiniz.", "error");
      }
    } else {
      // Kaldırılan etiketin anketItems listesinden silinmesi
      setAnketItems(prev => prev.filter(item => item !== value)); // Sadece değeri filtrele
    }
  };

  return (
    <>
      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Analiz verileri (TUİK verileri)</div>
          </div>
          <div className="grid">
            <div className="col-12 md:col-6">
              <Chart type="line" data={chartData} options={chartOptions} aria-label="Grafik 1" height="20rem" />
            </div>
            <div className="col-12 md:col-6">
              <Chart type="bar" data={chartData2} options={chartOptions2} aria-label="Grafik 1" height="20rem" />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="w-full surface-card">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Analiz Anketi</div>
          </div>

          <div className="flex flex-column align-items-start">
            <h5>
              OSB'leriniz içerisinde yer alan katılımcılarınız son 1 yıl içerisinde aşağıdaki sektör alanlarından
              hangisinde daha çok büyüme göstermiştir? (3 sektör seçiniz)
            </h5>
            <div className="grid">
              {options.map(option => (
                <div key={option.label} className="col-12 ml-3">
                  <Checkbox
                    inputId={option.label}
                    value={option.label}
                    onChange={handleCheckboxChange}
                    checked={anketItems.includes(option.label)} // İşaretli olup olmadığını kontrol et
                  />
                  <label htmlFor={option.label} className="ml-2">
                    {option.label}
                  </label>
                </div>
              ))}
            </div>

            <Button
              label="Gönder"
              type="submit"
              className="mt-3"
              onClick={handleSubmit}
              disabled={anketItems.length < 3}
            />
          </div>
        </div>
      </div>

      
      <ProtokolFirmalarComponent />
    </>
  );
};

export default Grafik;
