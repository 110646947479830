import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useToast } from "../../utils/toast";
import { CreateForumDto, ForumTopicEnum } from "../../services/forum/forum.dto";
import forumService from "../../services/forum/forum.service";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";

const getTurkishLabel = (key: string): string => {
  const labelMap: Record<string, string> = {
    GENEL: "Genel",
    YESIL_DONUSUM: "Yeşil Dönüşüm",
    DIJITAL_DONUSUM: "Dijital Dönüşüm",
    EGITIM: "Eğitim",
    IS_GELISTIRME: "İş Geliştirme",
    ARGE: "Ar-Ge",
    KAPASITE_GELISTIRME: "Kapasite Geliştirme",
    IS_SUREKLILIGI: "İş Sürekliliği"
  };

  return labelMap[key] || key;
};

const forumTopicOptions = Object.entries(ForumTopicEnum)
  .filter(([key, value]) => !isNaN(Number(value))) // Sadece sayısal değerleri filtrele
  .map(([key, value]) => ({
    label: getTurkishLabel(key), // Etiket (label) olarak Türkçe metin
    value: Number(value) // Value olarak sayısal değer
  }));


const ForumEkle = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const formik = useFormik<CreateForumDto>({
    initialValues: {
      forum_topic: null,
      forum_header: "",
      forum_content: ""
    },
    validate: values => {
      const errors: any = {};
      if (values.forum_header.length < 5) {
        errors.forum_header = "Başlık en az 5 karakter olmalıdır.";
      }
      if (values.forum_content.length < 10) {
        errors.forum_content = "Lütfen en az 10 karakter içeren bir açıklama yazınız.";
      }
      if (!values.forum_topic) {
        errors.forum_topic = "Lütfen bir forum konusu seçiniz.";
      }
      return errors;
    },
    onSubmit: async values => {
      try {
        const res = await forumService.CreateNewForm(values);
        console.log(res);
        if (res?.result?.error === null) {
          toast.show("Forum gönderiniz başarıyla oluşturuldu.", "success");
          const result: any = res?.result?.data;
          const forumId = result?.id;
          navigate(`/app/forum-ayrinti/${forumId}`);
        } else if (res.result === undefined) {
          toast.show("Sunucuyla bağlantı kurulamadı, lütfen internet bağlantınızı kontrol edin.", "error");
        } else {
          toast.show("Bir hata oluştu, lütfen tekrar deneyiniz.", "error");
        }
      } catch (error) {
        toast.show("Bir hata oluştu, lütfen tekrar deneyiniz.", "error");
      }
    }
  });

  return (
    <div className="card">
      <div className="mb-4 text-center">
        <h1 className="text-900 text-3xl font-bold">Forum Gönderisi Oluştur</h1>
        <p className="text-600 text-lg">Aşağıdaki formu doldurarak yeni bir forum gönderisi oluşturabilirsiniz.</p>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-column align-items-center justify-forum_content-center">
          <div className="grid w-12 p-0 md:p-2">
            <div className="col-12">
              <label htmlFor="forum_header" className="text-900 text-xl font-medium mb-2 block">
                Forum Başlığı
              </label>
              <InputText
                id="forum_header"
                name="forum_header"
                maxLength={100}
                placeholder="Eklenecek Forum için Başlık Giriniz"
                value={formik.values.forum_header}
                onChange={e => formik.setFieldValue("forum_header", e.target.value)}
                className="w-full"
              />
              {FormikValueIsValid(formik, "forum_header") && (
                <div className="p-error mb-1">{formik.errors.forum_header}</div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="forum_topic" className="text-900 text-xl font-medium mb-2 block">
                Forum Konusu
              </label>

              <Dropdown
                value={formik.values.forum_topic}
                onChange={(e: DropdownChangeEvent) => formik.setFieldValue("forum_topic", e.value)}
                options={forumTopicOptions}
                optionLabel="label"
                optionValue="value"
                placeholder="Forum Konusu Seçiniz"
                className="w-full md:w-18rem "
              />

              {FormikValueIsValid(formik, "forum_topic") && (
                <div className="p-error mb-1">{formik.errors.forum_topic}</div>
              )}
            </div>

            <div className="col-12">
              <label htmlFor="forum_content" className="text-900 text-xl font-medium mb-2 block">
                Forum Ayrıntısı
              </label>
              <InputTextarea
                id="forum_content"
                name="forum_content"
                placeholder="Eklenecek Forum için İçeriği Giriniz"
                value={formik.values.forum_content}
                onChange={e => formik.setFieldValue("forum_content", e.target.value)}
                rows={5}
                cols={5}
                className="w-full"
              />
              {FormikValueIsValid(formik, "forum_content") && (
                <div className="p-error">{formik.errors.forum_content}</div>
              )}
            </div>

            {/* Submit Button */}
            <div className="col-12 mt-5">
              <div className="flex justify-forum_content-end">
                <Button label="Gönder ve Kaydet" type="submit" className="p-3 text-xl" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForumEkle;
