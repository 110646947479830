import React, { useState } from "react";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/auth";
import { useToast } from "../../utils/toast";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { t } from "i18next";
import LoginOsbuk from "./LoginOsbuk";
import LoginSebis from "./LoginSebis";
import RegisterSebis from "../register/RegisterSebis";

const Login = () => {
  const options: string[] = ["OSBUK.net ile Giriş", "Giriş Yap", "Kayıt Ol"];
  const [value, setValue] = useState<string>(options[0]);

  return (
    <>
      <div
        className="flex flex-column align-items-center justify-content-center"
        style={{ paddingTop: "5rem", paddingBottom: "2rem" }}
      >
        <SelectButton
          value={value}
          onChange={e => setValue(e.value)}
          options={options}
          allowEmpty={false}
          style={{ display: "flex" }}
          itemTemplate={option => (
            <div
              style={{
                width: "125px", // Sabit genişlik
                textAlign: "center", // Metni ortala
                fontWeight: "bold", // Kalın yazı
                fontSize: "1rem" // Yazı boyutu
              }}
            >
              {option}
            </div>
          )}
        />
      </div>
      {/* {renderContent()} */}
      {value === "OSBUK.net ile Giriş" && <LoginOsbuk />}
      {value === "Giriş Yap" && <LoginSebis />}
      {value === "Kayıt Ol" && <RegisterSebis />}
    </>
  );
};

export default Login;
