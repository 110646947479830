import { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import {
  CreateMessageDto,
  ForumMessageDTO,
  ForumTopicEnum,
  ForumWithMessagesDTO,
  getColorForLetter
} from "../../services/forum/forum.dto";
import forumService from "../../services/forum/forum.service";
import FormikValueIsValid from "../../utils/FormikValueIsValid";
import { useToast } from "../../utils/toast";
import { DataView } from "primereact/dataview";
import { useNavigate, useParams } from "react-router-dom";

interface Mesaj {
  mesaj: string;
  mesaj_kimden: string;
}

interface Topic {
  forum_header: string;
  message_content: string;
  forum_topic: ForumTopicEnum;
  mesajlar: Mesaj[];
  creatorName: string;
  creation_date: string;
  view_count: number;
  replies: number;
}

const ForumAyrinti = () => {
  const { idForum } = useParams<{ idForum: string }>();

  console.log("idForum", idForum);

  const [forum, setForum] = useState<ForumWithMessagesDTO | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [newMessage, setNewMessage] = useState(""); // Yeni mesaj için state
  const [currentUser, setCurrentUser] = useState("Mehmet"); // Simüle edilen kullanıcı adı
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (!idForum) {
      navigate("/forum");
      return;
    }
    forumService
      .GetForumById(Number(idForum), {
        blockUI: true
      })
      .then(res => {
        if (res?.result?.error === null) {
          console.log("res", res);
          setForum(res?.result?.data);
        } else {
          toast.show(res?.result?.error || "Hata oluştu.", "error");
        }
      });
  }, []);

  const formik = useFormik<CreateMessageDto>({
    initialValues: {
      forum_id: idForum ? Number(idForum) : 0,
      message_content: ""
    },
    validate: values => {
      const errors: any = {};

      if (values.message_content.length < 10) {
        errors.message_content = "Lütfen en az 10 karakter içeren bir yorum yazınız.";
      }
      return errors;
    },
    onSubmit: async values => {
      try {
        const res = await forumService.CreateNewMessage(values);
        if (res?.result?.error === null) {
          toast.show("Forum gönderiniz başarıyla oluşturuldu.", "success");
          // Sayfayı yenileme
          window.location.reload();
        } else {
          toast.show("Bir hata oluştu, lütfen tekrar deneyiniz.", "error");
        }
      } catch (error) {
        toast.show("Bir hata oluştu, lütfen tekrar deneyiniz.", "error");
      }
    }
  });

  const messageTemplate = (message: ForumMessageDTO, index: number) => {
    const firstLetter = message.creator_name.charAt(0).toUpperCase();
    const bgColor = getColorForLetter(firstLetter);

    return (
      <div
        className={classNames("col-12 flex flex-row p-2 gap-3 border-round", {
          "border-top-1 surface-border": index !== 0
        })}
        key={index}
      >
        <div
          className="w-3rem h-3rem flex justify-content-center align-items-center text-white text-xl font-bold border-circle shadow-2 my-1"
          style={{ backgroundColor: bgColor }}
        >
          <div className="flex align-items-center justify-content-center" style={{ height: "100%" }}>
            {firstLetter}
          </div>
        </div>

        <div className="flex flex-column flex-1">
          <div className="flex align-items-center gap-2 text-sm text-600">
            <div className="flex align-items-center gap-2">
              <i className="pi pi-user text-primary-500"></i>
              <span className="font-medium text-secondary-900">{message.creator_name}</span>
            </div>
            <div className="flex align-items-center gap-2 ml-3">
              <i className="pi pi-calendar text-blue-500"></i>
              <span>
                {new Date(message.created_at).toLocaleDateString()} -
                {new Date(message.created_at).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
              </span>
            </div>
          </div>
          <div className="text-md text-900 mt-2">{message.message_content}</div>
        </div>
      </div>
    );
  };

  if (!forum) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <div className="">
      <div className="card p-4 mb-4">
        <div className="mb-3">
          <h2 className="text-2xl font-bold text-primary-700">{forum.forum_header}</h2>
        </div>

        {/* <div className="mb-3">
          <span className="text-sm text-secondary-900 font-medium">{forum.forum_topic}</span>
        </div> */}

        <p className="text-sm text-700 mt-3 line-height-3">{forum.forum_content}</p>

        <div className="flex justify-between align-items-center text-sm text-600">
          <div className="flex align-items-center gap-2">
            <i className="pi pi-user text-primary-500"></i>
            <span className="font-medium text-secondary-900">{forum.creator_name}</span>
          </div>

          <div className="flex align-items-center gap-2 ml-3">
            <i className="pi pi-calendar text-blue-500"></i>
            <span>
              {new Date(forum.creation_date).toLocaleDateString()} -
              {new Date(forum.creation_date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </span>
          </div>
        </div>
      </div>

      <div className="card p-3">
        <DataView
          value={forum.messages}
          itemTemplate={messageTemplate as any}
          layout="list"
          emptyMessage="Henüz hiç yorum yapılmış."
        />
      </div>

      <div className="card mt-4 p-4">
        <h3 className="text-xl font-bold mb-4 text-primary-700">Yeni Mesaj Gönder</h3>
        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-4">
          <div>
            <label htmlFor="message_content" className="block text-sm font-medium text-700 mb-2">
              Mesajınız
            </label>
            <InputTextarea
              id="message_content"
              name="message_content"
              value={formik.values.message_content}
              onChange={e => formik.setFieldValue("message_content", e.target.value)}
              rows={5}
              cols={5}
              style={{ minHeight: "150px" }}
              placeholder="Mesajınızı buraya yazın..."
              className={classNames("w-full", { "p-invalid": FormikValueIsValid(formik, "message_content") })}
            />
            {FormikValueIsValid(formik, "message_content") && (
              <small className="p-error">{formik.errors.message_content}</small>
            )}
          </div>

          <div className="flex justify-message_content-end">
            <Button
              label="Gönder"
              type="submit"
              className="p-button-primary p-3 text-md shadow-3"
              icon="pi pi-send"
              iconPos="right"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForumAyrinti;
