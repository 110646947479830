import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  StratejikAlanlar,
  FormResponse,
  SortedItem,
  CalistayAnket1,
  SortedItemAnket1,
  AnketYorumDto,
  AlanSonuc,
  YorumSonuc,
  CalistayAnket4_1DTO
} from "./calistay.dto";
import { Kisi } from "../../pages/calistay/MasaSonucListe";

const calistayServices = {
  AnketStratejikAlanlar1(dto: CalistayAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket1", updatedDto, config);
  },
  AnketYesilDonusum2(dto: StratejikAlanlar, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/hesaplama", dto, config);
  },
  AnketDijitalDonusum3(dto: StratejikAlanlar, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/hesaplama", dto, config);
  },
  AnketEgitim4(dto: StratejikAlanlar, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/hesaplama", dto, config);
  },
  AnketIsGelistirme5(dto: StratejikAlanlar, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/hesaplama", dto, config);
  },

  AnketSirala1(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan1", updatedDto, config);
  },
  AnketSirala2(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan2", updatedDto, config);
  },
  AnketSirala3(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan3", updatedDto, config);
  },
  AnketSirala4(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan4", updatedDto, config);
  },
  AnketSirala5(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan5", updatedDto, config);
  },
  AnketSirala6(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan6", updatedDto, config);
  },
  AnketSirala7(dto: SortedItemAnket1, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-alan7", updatedDto, config);
  },

  AnketAlanSonuc1(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan1-sonuc", { userName: userName }, config);
  },
  AnketAlanSonuc2(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan2-sonuc", { userName: userName }, config);
  },
  AnketAlanSonuc3(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan3-sonuc", { userName: userName }, config);
  },
  AnketAlanSonuc4(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan4-sonuc", { userName: userName }, config);
  },
  AnketAlanSonuc5(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan5-sonuc", { userName: userName }, config);
  },
  AnketAlanSonuc6(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan6-sonuc", { userName: userName }, config);
  },
  AnketAlanSonuc7(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AlanSonuc[]>("forms/anket-alan7-sonuc", { userName: userName }, config);
  },

  AnketYorum1(dto: AnketYorumDto, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket-yorum1", updatedDto, config);
  },

  AnketYorumSonuc1(anket: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<YorumSonuc[]>("forms/anket-yorum1-sonuc", { anket: anket }, config);
  },

  MasaSonuc(config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/masa-sonuc", config);
  },

  MasaSonucList(config?: AxiosRequestConfig<any> | undefined) {
    return http.post<Kisi[]>("forms/masa-sonuc-list", config);
  },


  Anket4Isimler(userName: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<AnketYorumDto>("forms/anket4isim", { userName: userName }, config);
  },

  Anket4Kaydet(dto: CalistayAnket4_1DTO, config?: AxiosRequestConfig<any> | undefined) {
    const updatedDto = {
      ...dto,
      username: localStorage.getItem("userName") || ""
    };
    return http.post<FormResponse>("forms/anket41", updatedDto, config);
  },

  Anket4Sonuc(anket: string, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<YorumSonuc[]>("forms/anket41-sonuc", { anket: anket }, config);
  },
};

export default calistayServices;
