import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  HesaplamaAracAnketRequestDto,
  FormResponse,
  RaporlamaRequestDto,
  GrafikAnketRequestDto,
  AnketRequestDto
} from "./form.dto";

const services = {
  karbonAyakIziForm(dto: HesaplamaAracAnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/hesaplama", dto, config);
  },
  raporForm(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/rapor", dto, config);
  },

  GrafikAnket(dto: GrafikAnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/grafik", dto, config);
  },

  egitimAnket(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/egitim", dto, config);
  },
  afadAnket(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/afad", dto, config);
  },
  haberAnket(dto: AnketRequestDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<FormResponse>("forms/haber", dto, config);
  }
};

export default services;
